import React from 'react';
import { Snackbar, Slide } from '@material-ui/core';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const MessageSnackbar = ({snackbarMessages}) => {
  const [snackPack, setSnackPack] = React.useState({message: '', time: ''});
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [transition, setTransition] = React.useState(undefined);


  React.useEffect(() => {
    if (snackPack.time !== snackbarMessages.time && !messageInfo) {
      // Set a new snack when we don't have an active one
      setSnackPack(() => ({...snackbarMessages}));
      setMessageInfo(() => snackPack);
      setTransition(() => TransitionUp);
      setOpen(true);
    } else if ((snackPack.time !== snackbarMessages.time) && messageInfo && open) {
      // Close an active snack when a new one is added
      setMessageInfo(undefined);
      setOpen(false);
    }
  }, [snackPack, messageInfo, open, snackbarMessages]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  
  return (
    <Snackbar
      key={messageInfo ? messageInfo.message : undefined}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      TransitionComponent={transition}
      autoHideDuration={4000}
      message={snackbarMessages.message}
    />
  )
}

export default MessageSnackbar;
